import { isExperimentActive, selectExperiments } from './experiments';
import { AUD_MOBILE_PROMO_CIAN_APPS } from '../../constants/experiments';
import { IApplicationState } from '../../types/redux';

export const selectIsMobilePromoCianAppsExperimentEnabled = (state: IApplicationState): boolean => {
  const experiments = selectExperiments(state);

  const bGroup = isExperimentActive(experiments, AUD_MOBILE_PROMO_CIAN_APPS, 'B');

  return bGroup;
};
