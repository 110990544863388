import * as React from 'react';
import { clsx as cx } from 'clsx';

import { EAppService } from '../../types/appService';
import { Link } from '../Link';

import * as styles from './CianGroup.css';
import * as gridStyles from '../../utils/grid.css';
import { CianLogo } from '../CianLogo';
import { AppsList } from './AppsList/AppsList';

interface ICianGroupProps {
  withSiteTypeChanging: boolean;
  onDownloadAppClick(event: React.MouseEvent<HTMLAnchorElement>, appService: EAppService): void;
  onSiteTypeChange?(event: React.MouseEvent<HTMLAnchorElement>): void;
  onMobileAppClick(): void;
}

export const CianGroup: React.FC<ICianGroupProps> = ({
  withSiteTypeChanging,
  onDownloadAppClick,
  onSiteTypeChange,
  onMobileAppClick,
}) => (
  <div className={cx(styles['group'], gridStyles['cg-col-24'])}>
    <CianLogo className={styles['logo']} />
    {withSiteTypeChanging && (
      <>
        <div className={styles['tomobile']} data-testid="LinkToMobileVersion">
          <Link href="/" theme="default" underlined onClick={onSiteTypeChange}>
            Мобильная версия сайта
          </Link>
        </div>
        <div className={styles['tomobile']} data-testid="LinkToMobileApp">
          <Link href="/mobile-app/" theme="primary" onClick={onMobileAppClick}>
            О приложении
          </Link>
        </div>
      </>
    )}
    <AppsList onDownloadAppClick={onDownloadAppClick} />
    <span className={styles['age']}>+16</span>
  </div>
);
