import { IExperiment } from '../../types/experiments';
import { IApplicationState } from '../../types/redux';

export const selectExperiments = (state: IApplicationState): IExperiment[] => state.experiments;

export function getExperiment(experiments: IExperiment[], experimentName: string): IExperiment | undefined {
  return experiments.find(o => o.experimentName === experimentName);
}

export function isExperimentActive(experiments: IExperiment[], experimentName: string, groupName: string): boolean {
  const experiment = getExperiment(experiments, experimentName);

  if (!experiment) {
    return false;
  }

  return experiment.groupName === groupName;
}
