import { isExperimentActive, selectExperiments } from './experiments';
import { BETTER_IN_APP_EXPERIMENT } from '../../constants/experiments';
import { IApplicationState } from '../../types/redux';

export const selectBetterInAppExperimentGroupB = (state: IApplicationState): boolean => {
  const experiments = selectExperiments(state);

  const bGroup = isExperimentActive(experiments, BETTER_IN_APP_EXPERIMENT, 'B');

  return bGroup;
};
