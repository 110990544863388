import * as React from 'react';
import { clsx as cx } from 'clsx';

import { EPlatform } from '../../../types/platform';
import * as styles from './DownloadAppBanner.css';
import { AppLink } from '../../AppLink';

interface IDownloadAppBannerProps {
  isPinnedButtonActive: boolean;
  isMobilePromoCianAppsExperimentEnabled: boolean;
  platformType: EPlatform;
  onDownloadAppClick(event: React.MouseEvent<HTMLAnchorElement>, platform: EPlatform): void;
  onMobileAppClick(): void;
}

export const DownloadAppBanner: React.FC<IDownloadAppBannerProps> = ({
  isPinnedButtonActive,
  isMobilePromoCianAppsExperimentEnabled,
  onMobileAppClick,
  ...props
}) => {
  return (
    <div className={cx(styles['banner'], isPinnedButtonActive && styles['with_pinnedbutton'])}>
      <div className={styles['image']}></div>
      <span className={styles['download-text']}>Приложение быстрее и удобнее</span>
      <AppLink {...props} />
      {isMobilePromoCianAppsExperimentEnabled && (
        <a
          href="/mobile-app/"
          target="_blank"
          data-testid="LinkToMobileApp"
          className={styles['link']}
          onClick={onMobileAppClick}
        >
          Подробнее
        </a>
      )}
    </div>
  );
};
