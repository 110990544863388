import * as React from 'react';
import { useSelector } from 'react-redux';

import { IApplicationState } from '../../types/redux';
import { EPlatform } from '../../types/platform';
import { MobileFooter } from '../../components/MobileFooter';
import { MOBILE_FOOTER_TYPES } from '../../constants/footerTypes';
import { selectEventCategory } from '../../selectors/eventCategory';
import { selectDownloadAppBannerDisabled } from '../../selectors/downloadAppBanner';
import { trackDownloadAppClick, trackMobileAppClick, trackSiteTypeChange } from './tracking';
import { selectBetterInAppExperimentGroupB } from '../../selectors/experiments/selectBetterInAppExperiment';
import { selectIsMobilePromoCianAppsExperimentEnabled } from '../../selectors/experiments/selectIsMobilePromoCianAppsExperimentEnabled';

export const MobileFooterContainer = () => {
  const footerType = useSelector((state: IApplicationState) => state.footerType);
  const platform = useSelector((state: IApplicationState) => state.platform);
  const showSiteTypeChanging = useSelector((state: IApplicationState) => state.showSiteTypeChanging);
  const eventCategory = useSelector(selectEventCategory);
  const isDownloadAppBannerDisabled = useSelector(selectDownloadAppBannerDisabled);
  const isPinnedButtonActive = useSelector(selectBetterInAppExperimentGroupB);
  const isMobilePromoCianAppsExperimentEnabled = useSelector(selectIsMobilePromoCianAppsExperimentEnabled);

  if (!MOBILE_FOOTER_TYPES.includes(footerType)) {
    return null;
  }

  const handleSiteTypeChange = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    trackSiteTypeChange(eventCategory);

    document.cookie = 'flavour=desktop;path=/;domain=.cian.ru';
    location.reload();
  };

  const handleDownloadAppClick = (event: React.MouseEvent<HTMLAnchorElement>, platform: EPlatform) => {
    trackDownloadAppClick({ category: eventCategory, platform });
  };

  const handleMobileAppClick = () => {
    trackMobileAppClick();
  };

  return (
    <MobileFooter
      type={footerType}
      platform={platform}
      onSiteTypeChange={handleSiteTypeChange}
      onDownloadAppClick={handleDownloadAppClick}
      onMobileAppClick={handleMobileAppClick}
      withSiteTypeChanging={showSiteTypeChanging}
      isDownloadAppBannerDisabled={isDownloadAppBannerDisabled}
      isPinnedButtonActive={isPinnedButtonActive}
      isMobilePromoCianAppsExperimentEnabled={isMobilePromoCianAppsExperimentEnabled}
    />
  );
};
